import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { AppServiceService } from '../../../../api/app-service.service';
import { IonInput } from '@ionic/angular';
import consts from '../constatnts.json';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

import { HttpClient } from '@angular/common/http';

declare var config: any;

@Component({
  selector: 'app-modal-popover',
  templateUrl: './modal-popover.page.html',
  styleUrls: ['./modal-popover.page.scss'],
})
export class ModalPopoverPage implements OnInit {
  @ViewChild('googleaddressweb', { read: IonInput }) googleaddress: IonInput;
  @ViewChild('crossSelling') crossSelling: any;

  POPOVER_TYPES = consts.POPOVER_TYPES;

  // This will keep records while navigate between components inside popover
  // Used to manage navigateBackward function
  historyStack: any[] = [];

  @Input() name: string;
  @Input() type: string;
  // always send the title as language key
  @Input() title: string;

  // when page click, slug need to be sent
  @Input() slug = null;

  @Input() isOrderProcessing: boolean = false;

  // Dish image preview url for DISH_IMAGE_PREVIEW
  @Input() dishImageURL = '';

  // cross selling component data set
  @Input() crossSellingProducts = [];
  @Input() selectedDishCategory;
  @Input() isFromCrossSelling: boolean;
  isInQuestionDishesPage: boolean = false;
  selectedQuestionCategory: any = null;

  @Input() justSelectDeliveryAddress: boolean = false;

  // close popup after selecting delivery address
  // @Input() closeAfterSelection: boolean = false;

  isContentLoading: boolean = false;
  isRestaurantsAvailable: boolean = true;

  address = '';

  @Input() htmlContent: string = '';

  // payment id after placing order in checkout
  @Input() uniqueId: string = '';

  // reservation success message html content
  reservationSuccess: string = '';

  @Input() selectedBranch: any = null;

  previousAddressData: any = null;
  addressInputChanged: boolean = false;

  public isFromCheckoutToLocationSelect: boolean = false;
  public backFromAddressSelect: boolean = false;

  // dine in available table list of selected restaurant
  @Input() selectedRestaurantTableList: any[] = [];
  @Input() justToSelectATable: boolean = false;
  @Input() origin: string = '';
  public loadingTables: boolean = false;
  public selectedTable: any = null;
  public selectedRestaurant: any = null;
  public showPreviousPendingPaymentOrder: boolean = false;
  public previousRestaurant: any = null;

  // select favourite restaurant modal status
  @Input() selectfavouriteRestaurant: boolean = false;
  // keep temp selected ordering type to use when franchise mode is on
  public tempSelectedOrderType: string = '';
  public loadingFavourite: boolean = false;
  public redirectIntervel: any = null;
  public favouriteRedirectCountDown: number = 10;

  // when franchise mode is on, there is a special way to get available delivery methods
  // check all the branches and get all available delivery methods
  public availableDeliveryMethodsFranchise: string[] = [];

  // select just order type from menu page
  @Input() justSelectOrderType: boolean = false;

  public favouriteRestaurantDeliveryMethods: string[] = [];
  @Input() fromFavouriteIcon: boolean = false;

  /**
   * when user already has a favourite restauratn and select delivery as order method 
   * so need to select where to deliver
   */
  public isFavouriteRestaurantDeliverySearch: boolean = false;

  //postal code 
  public postalCodeRestaurantList = [];
  public postalCodeList = [];
  public selectedArea = null;
  public postalFilterItem: any[] = [];
  public isPostalItemAvailable = false;
  public isPostalModeEnable = false;
  public selected_postal = '';
  public lang: any = null;


  public todayDate = new Date();
  constructor(
    public modalCtr: ModalController,
    public appService: AppServiceService,
    private router: Router,
    private httpClient: HttpClient,
    public sanitizer: DomSanitizer,
    public translate: TranslateService
  ) { }

  async ngOnInit() {

    if (!this.selectedBranch && this.appService.appObject.settings && this.appService.appObject.settings.is_franchise === 'true' && this.type === this.POPOVER_TYPES.DELIVERY_TYPE.TYPE) {
      this.availableDeliveryMethodsFranchise = this.appService.getAvailableOrderMethodsFromBranches();
    } else if (this.selectedBranch) {
      this.appService.findSelectedRestaurantOrderTypesV2(this.selectedBranch);
    } else if (this.justSelectOrderType) {
      this.availableDeliveryMethodsFranchise = this.appService.findSelectedRestaurantOrderTypesV2(this.appService.appObject.selectedRestaurantDetails, true).deliveryMethods;
    }

    if (this.type === 'delivery-type' && !this.selectedBranch && !this.justToSelectATable && this.appService.appObject.settings.is_franchise === 'true' && this.appService.appObject.settings.app_configuration?.has_multiple_restaurants && this.appService.appObject.favouriteRestaurant) {
      // console.log('has favourite');
      this.type = this.POPOVER_TYPES.FAVOURITE_RESTAURANT.TYPE;
      this.title = this.POPOVER_TYPES.FAVOURITE_RESTAURANT.TITLE;
      // this.currentUI = 'redirect-to_favourite';
      this.loadingFavourite = true;
      await this.appService.getFavouriteRestaurantData(this.appService.appObject.favouriteRestaurant.slug);
      this.favouriteRestaurantDeliveryMethods = this.appService.findSelectedRestaurantOrderTypesV2(this.appService.appObject.favouriteRestaurant, true).deliveryMethods;
      this.loadingFavourite = false;
      // this.redirectIntervel = setInterval(() => {
      //   this.favouriteRedirectCountDown--;
      //   if (this.favouriteRedirectCountDown === 0) {
      //     this.clearRedirectInterval();
      //     this.redirectTofavouriteRestaurant();
      //   }

      // },1000);

      return;
    }



    // enable google auto complete for search near by restaurants for make favourite
    if (this.selectfavouriteRestaurant) {
      if (navigator.geolocation && !this.appService.appObject.isAddressHave) {
        this.getCurrentLocation();
      } else if (!this.appService.appObject.myAddress || !this.appService.appObject.myAddress.name) {
        this.address = '';
      }

      setTimeout(() => {
        this.appService.initGoogle(this.googleaddress);
      }, 1000);
    }

    // set custom size to modal when restaurant select or restaurant selected message popup open
    if (this.type === this.POPOVER_TYPES.FAVOURITE_RESTAURANT_SELECT.TYPE) {
      let elem = document.getElementsByClassName('modal-wrapper');
      if (elem && elem.length) elem.item(0).setAttribute('style', 'width:350px;height:125px;');
    } else if (this.type === this.POPOVER_TYPES.FAVOURITE_RESTAURANT_SELECT.TYPE || this.type === this.POPOVER_TYPES.FAVOURITE_RESTAURANT_SELECTED.TYPE) {
      let elem = document.getElementsByClassName('modal-wrapper');
      if (elem && elem.length) elem.item(0).setAttribute('style', 'width:350px;height:150px;');
    }

    if (this.appService.appObject.settings.is_postalcode === 'true') {
      this.getPostalCodeList();
      // set if already has selected postal area in app object
      if (this.justSelectDeliveryAddress && this.appService.appObject.postalCodeDetails) {
        this.selectedArea = this.appService.appObject.postalCodeDetails;
        this.selected_postal = this.selectedArea.code + ' - ' + this.selectedArea.name;
      }
    }
    this.getLang();
  }

  /**
   * Ask to select favourite restaurant accept triggering function
   */
  chooseFavouriteRestaurant() {
    this.modalCtr.dismiss({ status: true });
  }

  /**
   * After entering address search restaurants for mark as favourites
   */
  async favouriteRestaurantSearch() {
    await this.appService.getRestaurantListForFavourite();
    this.selectfavouriteRestaurant = true;
    this.type = this.POPOVER_TYPES.RESTAURANT_SELECT.TYPE;
    this.title = this.POPOVER_TYPES.RESTAURANT_SELECT.TITLE;
    this.historyStack.push(this.POPOVER_TYPES.FAVOURITE_RESTAURANT_SEARCH);
  }

  /**
  * Clear user redirect to favourite restaurant interval
  * @param dismiss dismiss after clear interval
  */
  clearRedirectInterval(dismiss: boolean = false) {
    if (this.redirectIntervel) {
      clearInterval(this.redirectIntervel);
      this.redirectIntervel = null;
    }

    if (dismiss) this.close();
  }

  /**
   * Redirect user to favourite 
   */
  redirectTofavouriteRestaurant() {
    if (this.appService.appObject.favouriteRestaurant && this.appService.appObject.favouriteRestaurant.slug) {
      this.router.navigate(['/menu', this.appService.appObject.favouriteRestaurant.slug]);
      if (this.redirectIntervel) {
        clearInterval(this.redirectIntervel);
        this.redirectIntervel = null;
      }
      this.close();
    }
  }

  changeFavouriteRestaurant() {
    this.appService.appObject.favouriteRestaurant = null;
    this.appService.updateAppObject();
    this.selectfavouriteRestaurant = true;
    this.type = this.POPOVER_TYPES.FAVOURITE_RESTAURANT_SEARCH.TYPE;
    this.title = this.POPOVER_TYPES.FAVOURITE_RESTAURANT_SEARCH.TITLE;

    this.clearRedirectInterval();

    if (navigator.geolocation && !this.appService.appObject.isAddressHave) {
      this.getCurrentLocation();
    } else if (!this.appService.appObject.myAddress || !this.appService.appObject.myAddress.name) {
      this.address = '';
    } else if (this.appService.appObject.myAddress && this.appService.appObject.myAddress.name) {
      this.address = this.appService.appObject.myAddress.name;
    }

    setTimeout(() => {
      this.appService.initGoogle(this.googleaddress);
    }, 1000);
  }

  ngAfterViewInit() {
    if (this.type === this.POPOVER_TYPES.LOCATION_SELECT.TYPE || this.type === this.POPOVER_TYPES.FAVOURITE_RESTAURANT_SEARCH.TYPE) {
      setTimeout(async () => {
        await this.appService.initGoogle(this.googleaddress);
        // get existing address from app object each time go to the address select ui
        // if (this.type === this.POPOVER_TYPES.LOCATION_SELECT.TYPE) {

        // }
        this.address = this.appService.appObject.myAddress && this.appService.appObject.myAddress.name ? this.appService.appObject.myAddress.name : '';
      }, 1000);
    }
  }

  async close(closingData: any = null) {
    // if user close without choosing an address, previous address data will reassigned
    if (this.historyStack.length && this.historyStack[this.historyStack.length - 1].TYPE === this.POPOVER_TYPES.CHECKOUT.TYPE && this.type === this.POPOVER_TYPES.LOCATION_SELECT.TYPE && this.addressInputChanged) {
      this.appService.appObject.myAddress = { ...this.previousAddressData };
    }
    await this.modalCtr.dismiss(closingData);
  }

  /**
   * Will trigger when user click back btn from the view delivery address select
   */
  navigateBackward() {
    if (this.historyStack.length) {
      // if user came back from location select ui to checkout page this will trigger
      if (this.historyStack[this.historyStack.length - 1].TYPE === this.POPOVER_TYPES.CHECKOUT.TYPE && this.type === this.POPOVER_TYPES.LOCATION_SELECT.TYPE) {
        if (this.addressInputChanged) this.appService.appObject.myAddress = { ...this.previousAddressData };
        this.backFromAddressSelect = true;
      }
      // get previous page and navigate
      this.navigateTo(this.historyStack[this.historyStack.length - 1], null, true);

      // remove the last record each time user navigate to previous page
      this.historyStack.splice(this.historyStack.length - 1, 1);
    }
  }

  /**
   * Navigate to another modal content or a page in web version
   * @param location the location to navigate
   * @param extraData extra data set if want to
   * @param isBackwardNavigating check wether user navigating backward (this one use to manage historyStack array)
   */
  async navigateTo(location, extraData: any = null, isBackwardNavigating: boolean = false) {
    if (location === 'restaurant-list') {
      // clear error messages
      this.appService.appObject.errors = [];

      // clear my address data when user need all restaurants to select
      let getAllRestaurants = false;
      if (extraData === 'all') {
        getAllRestaurants = true;
      }
      setTimeout(() => {
        this.appService.appObject.isLoading = true;
      }, 100);
      // find restaurants
      this.appService.findNearByRestaurants(false, getAllRestaurants).then(async (response) => {
        this.appService.appObject.isLoading = false;

        // if has selected branch, check it's avialble for selected address to delivery
        // if it is then go to menu page, otherwise display an errror message
        if (this.selectedBranch) {
          let restaurant = this.appService.appObject.restaurants.find((restaurant) => restaurant.id === this.selectedBranch.id) // this.selectedBranch.id
          if (restaurant) {
            this.router.navigate(['/menu', restaurant.slug]);
            this.close();
            // await this.appService.getRestaurantBySlug(restaurant.slug).then((response) => {
            //   this.router.navigate(['/menu']);
            //   this.close();
            // });
          } else {
            this.appService.appObject.errors['address'] = ['selected_restaurant_cannot_delivery_to_selected_address'];
            return;
          }
        }
        // if multiple restaurants is disabled then navigate directly into main restaurant menu page
        if (this.appService.appObject.settings && this.appService.appObject.settings.app_configuration && !this.appService.appObject.settings.app_configuration.has_multiple_restaurants) {
          /**
           * 2022-10-07 9.18 p.m by Pumayk26
             * the restaurant won't deliver to the user selected location
             * haven't receive any restaurant nearby entered location this is a patch for an issue found in 'gusto bonn'
             * user entered an invalid address / not delivery location address, but still navigate into menu page
             * to avoid this issue added below if condition and check delivery method is 'delivery' but no restaurants returned by
             * 'findNearByRestaurants()' function
            **/
          /* PATCH START */
          if (this.appService.appObject.selectedDeliveryMethod === 'delivery' && !this.appService.appObject.restaurants.length) {
            this.appService.appObject.errors['address'] = ['sorry_we_are_not_delivery_to_this_location'];
            return;
          }
          /* PATCH END */

          if (this.appService.appObject.settings.main_restaurant && this.appService.appObject.settings.main_restaurant.slug) this.navigateTo('menu-page', this.appService.appObject.settings.main_restaurant);
          return;
        }
        if (this.appService.appObject.restaurants && this.appService.appObject.restaurants.length > 1) {
          // keep navigation history to navigate backward (just like in angular routing)
          if (getAllRestaurants) {
            this.historyStack.push(this.POPOVER_TYPES.DELIVERY_TYPE);
          } else {
            this.historyStack.push(this.POPOVER_TYPES.LOCATION_SELECT);
          }

          this.type = this.POPOVER_TYPES.RESTAURANT_SELECT.TYPE;
          // always send the title as language key
          this.title = this.POPOVER_TYPES.RESTAURANT_SELECT.TITLE;
        } else if (this.appService.appObject.restaurants && this.appService.appObject.restaurants.length === 1) {
          // keep navigation history to navigate backward (just like in angular routing)
          if (getAllRestaurants) {
            this.historyStack.push(this.POPOVER_TYPES.DELIVERY_TYPE);
          } else {
            this.historyStack.push(this.POPOVER_TYPES.LOCATION_SELECT);
          }

          this.type = this.POPOVER_TYPES.RESTAURANT_SELECT.TYPE;
          // always send the title as language key
          this.title = this.POPOVER_TYPES.RESTAURANT_SELECT.TITLE;
          // old code before 
          // if (this.appService.appObject.restaurants[0].slug) this.navigateTo('menu-page', this.appService.appObject.restaurants[0]);
        } else if (!this.appService.appObject.restaurants || !this.appService.appObject.restaurants.length) {
          this.appService.appObject.errors['address'] = ['selected_restaurant_cannot_delivery_to_selected_address'];
          this.translate.get('key.there_is_no_' + this.appService.appObject.selectedDeliveryMethod + '_available_restaurants_at_the_moment').toPromise().then((data) => {
            this.appService.showToast(data, 'danger', 'bottom', 5000);
          }).catch((err) => {
            console.log('error while fetching not available : ', err);
          })
        }

      });
    } else if (location === 'menu-page') {
      if (extraData) {
        // if bar mode enabled and selected order type is dine_in, then allow user to select a table before ordering
        if (extraData.is_bar_mode === 'true' && this.appService.appObject.selectedDeliveryMethod === 'dine_in') {
          this.handleBarModeNavigations(extraData);
          // this.appService.appObject.isLoading = true;
          // this.selectedRestaurantTableList = [];
          // this.selectedTable = null;
          // // get user unpaid orders if availabel to avoid placing orders on table else
          // await this.appService.getUserUnpaidActiveOrders();
          // // check if user have a pending order in kitchen, otherwise allow user to select a table and procced order
          // if (this.appService.appObject.orderObject && this.appService.appObject.orderObject.selectedTable) {
          //   // get unpaid order restaurant details
          //   let selectedRestaurant = this.appService.appObject.restaurants.find((rest) => rest.id === this.appService.appObject.orderObject.resturent_id);
          //   // if the current selected restaurant and the previously ordered restaurnt is the same, Then proceed
          //   // otherwise show a message to the user that he has an pending payment order in another restaurant and redirect into that restaurant
          //   if (this.appService.appObject.orderObject.resturent_id === extraData.id) {
          //     this.appService.appObject.selectedRestaurantId = extraData.id;
          //     await this.appService.updateAppObject();
          //     this.router.navigate(['/menu', selectedRestaurant.slug]);
          //     this.close();
          //   } else if (this.appService.appObject.orderObject.resturent_id === selectedRestaurant.id) {
          //     this.type = this.POPOVER_TYPES.TABLE_SELECT.TYPE;
          //     this.title = this.POPOVER_TYPES.TABLE_SELECT.TITLE;

          //     this.showPreviousPendingPaymentOrder = true;
          //     this.previousRestaurant = {
          //       name: selectedRestaurant.name,
          //       number: this.appService.appObject.orderObject.selectedTable.number,
          //       countDown: 5,
          //       slug: selectedRestaurant.slug
          //     };
          //     // clear the history stack, no need to navigate back because user will redirect into the restaurant menu and popup will be closed
          //     this.historyStack = [];
          //     this.appService.appObject.isLoading = false;
          //     let interval = setInterval((int) => {
          //       this.previousRestaurant.countDown--;
          //       // console.log('this.previousRestaurant: ' , this.previousRestaurant.count);
          //       if (this.previousRestaurant.countDown === 0) {
          //         clearInterval(interval);

          //         this.router.navigate(['/menu', this.previousRestaurant.slug]);
          //         this.close();
          //       }
          //     }, 1000)
          //   }
          // } else {
          //   this.historyStack.push(this.POPOVER_TYPES.RESTAURANT_SELECT);
          //   this.type = this.POPOVER_TYPES.TABLE_SELECT.TYPE;
          //   this.title = this.POPOVER_TYPES.TABLE_SELECT.TITLE;
          //   // keep restaurant data to navigate after selecting table
          //   this.selectedRestaurant = extraData;

          //   this.appService.fetchAllTablesOfRestaurant(extraData.id).then((response: any) => {
          //     // console.log('table data : ' , response);
          //     if (response.status) this.selectedRestaurantTableList = response.data;

          //     this.appService.appObject.isLoading = false;
          //   }).catch((error) => {
          //     console.log('error while fetching tables : ', error);
          //     this.appService.appObject.isLoading = false;
          //   });
          // }
        } else {
          if (this.selectfavouriteRestaurant) {
            this.appService.appObject.favouriteRestaurant = extraData;
            await this.appService.updateAppObject();
          }
          this.router.navigate(['/menu', extraData.slug], {
            queryParams: {
              markedAsFavourite: this.selectfavouriteRestaurant ? true : false
            }
          });
          this.close();
        }
        // this.appService.getRestaurantBySlug(extraData.slug).then((response) => {
        //   this.router.navigate(['/menu']);
        //   this.close();
        // });
      } else {
        this.router.navigate(['/menu', this.appService.appObject.selectedRestaurant]);
        this.close();
      }
    } else if (location && location.TYPE && location.TITLE) {
      // keep navigation history to navigate backward (just like in angular routing)
      // skip recording when user navigate backward with "isBackwardNavigating" condition
      // the second condition will prevent user go back to login form after login in to the user profile
      if (!isBackwardNavigating && !(location.TYPE === this.POPOVER_TYPES.USER_PROFILE.TYPE && this.type === this.POPOVER_TYPES.LOGIN.TYPE)) {
        Object.keys(this.POPOVER_TYPES).map((key) => {
          if (this.POPOVER_TYPES[key].TYPE === this.type) {
            this.historyStack.push(this.POPOVER_TYPES[key]);
          }
        });
      }

      // this will prevent user go back to registration form after successfuly registered and navigate in to the user profile
      // or user came to login page from checkout page and redirected into checkout page after logged in (prevent go back to login ui)
      // or user came to checkout page from login > register , and checkout, then prevent go back to registration page
      // or user came to order summary from checkout page and prevent go back to checkout page from summary page
      // or user came to delivery address select from login page prevent go back to login page
      if ((location.TYPE === this.POPOVER_TYPES.USER_PROFILE.TYPE && (this.type === this.POPOVER_TYPES.REGISTER.TYPE || this.type === this.POPOVER_TYPES.REGISTER_VIA.TYPE))
        || ((this.type === this.POPOVER_TYPES.LOGIN.TYPE
          || this.type === this.POPOVER_TYPES.REGISTER.TYPE
          || this.type === this.POPOVER_TYPES.REGISTER_VIA.TYPE)
          && location.TYPE === this.POPOVER_TYPES.CHECKOUT.TYPE)
        || this.type === this.POPOVER_TYPES.CHECKOUT.TYPE && location.TYPE === this.POPOVER_TYPES.ORDER_SUMMARY.TYPE
        || this.type === this.POPOVER_TYPES.LOGIN.TYPE && location.TYPE === this.POPOVER_TYPES.LOCATION_SELECT.TYPE) {
        this.historyStack = [];
      }

      // get existing address from app object each time go to the address select ui or search nearby ui
      if (location.TYPE === this.POPOVER_TYPES.LOCATION_SELECT.TYPE || location.TYPE === this.POPOVER_TYPES.FAVOURITE_RESTAURANT_SEARCH.TYPE) {
        this.address = this.appService.appObject.myAddress && this.appService.appObject.myAddress.name ? this.appService.appObject.myAddress.name : '';
      }
      // if user navigate to location select from checkout page, keep a record that will return from location select page to checkout page any time
      if (this.type === this.POPOVER_TYPES.CHECKOUT.TYPE && location.TYPE === this.POPOVER_TYPES.LOCATION_SELECT.TYPE) {
        // keep if there is an address already selected
        if (this.appService.appObject.myAddress) this.previousAddressData = { ...this.appService.appObject.myAddress };

        this.isFromCheckoutToLocationSelect = true;
      } else {
        this.isFromCheckoutToLocationSelect = false;
      }

      this.type = location.TYPE;
      this.title = location.TITLE;

      // reset history stack if the current page is login, once user reset password successfuly navigate to login
      // login is a main page so no need to go back to password reset ui
      if (this.type === this.POPOVER_TYPES.LOGIN.TYPE) {
        this.historyStack = [];
      }

      // if next page is for select locations to find restaurants, then initialize google map service
      if (location.TYPE === this.POPOVER_TYPES.LOCATION_SELECT.TYPE || location.TYPE === this.POPOVER_TYPES.FAVOURITE_RESTAURANT_SEARCH.TYPE) {
        if (!this.isPostalModeEnable) {
          if (navigator.geolocation && !this.appService.appObject.isAddressHave) {
            this.getCurrentLocation();
          } else if (!this.appService.appObject.myAddress || !this.appService.appObject.myAddress.name) {
            this.address = '';
          }

          setTimeout(() => {
            this.appService.initGoogle(this.googleaddress);
          }, 1000);
        } else {
          // set if already has selected postal area in app object
          if (this.appService.appObject.postalCodeDetails) {
            this.selectedArea = this.appService.appObject.postalCodeDetails;
            this.selected_postal = this.selectedArea.code + ' - ' + this.selectedArea.name;
          }
        }
      }
    }
  }

  /**
   * Clear selected postal data 
   */
  clearPostalArea() {
    this.selectedArea = null;
    this.selected_postal = '';
    this.appService.appObject.postalCodeDetails = null;
    this.appService.updateAppObject();
  }

  getCurrentLocation() {
    this.appService.appObject.isLoading = true;
    navigator.geolocation.getCurrentPosition((position) => {
      const longitude = position.coords.longitude;
      const latitude = position.coords.latitude;


      this.httpClient.get("https://maps.googleapis.com/maps/api/geocode/json?latlng=" + latitude + "," + longitude + "&sensor=true&key=" + config.gkey).toPromise().then((response: any) => {
        // console.log('response : ', response)
        if (response.status === 'OK') {
          let place = null;
          let isAddressValid = false;
          for (let i = 0; i < response.results.length; i++) {
            let validateResult = this.appService.validateAddressComponents(response.results[i].address_components);
            // if place is null, then assign one place object to get formatted address
            place = !place ? response.results[i] : place;

            if (this.appService.isAddressComponentValid(validateResult)) {
              isAddressValid = true;
              place = response.results[i];
              break;
            }
          }

          // console.log('addressValidating : ' , addressValidating);
          if (isAddressValid && place) {
            // console.log('place : ' , place);
            let postal, city, road = '';
            this.address = '';

            for (let i = 0; i < place['address_components'].length; i++) {
              let result = place['address_components'][i];
              if (result['types'][0] == "postal_code") {
                postal = result['short_name'];
              }
              if (result['types'][0] == "locality") {
                city = result['short_name'];
              }
              if (result['types'][0] == "route") {
                road = result['short_name'];
              }

              if (postal && city && road) {
                this.address = road + ', ' + postal + ', ' + city;
              }
            }


            this.appService.appObject.isAddressHave = true;
            // let address_splited = place.formatted_address.split(',');
            // this.address = place.formatted_address;
            // let country = address_splited[address_splited.length - 1];

            // if (country.trim() === 'Germany') {
            //   this.address = this.address.replace(", Germany", "");
            // }

            this.appService.appObject.myAddress.name = this.address;
            // console.log(this.appService.appObject.myAddress.name);
            this.appService.appObject.myAddress.lat = place.geometry.location.lat;
            this.appService.appObject.myAddress.lng = place.geometry.location.lng;
            this.appService.updateAppObject();
            this.appService.appObject.errors = [];
          } else {
            this.address = place.formatted_address;
            this.appService.appObject.isAddressHave = false;
            this.appService.appObject.myAddress.name = '';
            this.appService.appObject.myAddress.lat = '';
            this.appService.appObject.myAddress.lng = '';

            this.appService.updateAppObject();
            this.appService.appObject.errors['address'] = ['address_is_not_valid'];
          }
        }

        this.appService.appObject.isLoading = false;
      }).catch((error) => {
        console.log('google location error : ', error);
        this.appService.appObject.isLoading = false;
      });
    }, (error) => {
      this.appService.appObject.isLoading = false;
      if (error && error.code == error.PERMISSION_DENIED) {
        this.translate.get(['key.please_allow_location_permission_to_get_your_current_location']).toPromise().then((response) => {
          this.appService.showToast(response['key.please_allow_location_permission_to_get_your_current_location'] ? response['key.please_allow_location_permission_to_get_your_current_location'] : 'key.please_allow_location_permission_to_get_your_current_location');
        }).catch((error) => {
          console.log('error : ', error);
          this.appService.showToast('key.please_allow_location_permission_to_get_your_current_location');
        });
      }
    });
  }

  /**
   * This will pass as a callback into login component to trigger once user logged in
   * This also used to pass into registration component as a call back to get trigger once user successfuly registered and logged in
   * and return login operation response data set
   * @param loginResponse after login response object
   */
  async onLoginSuccess(loginResponse) {
    await this.appService.updateAppObject();
    if (this.appService.appObject.isOrderProcess) {
      if (this.appService.appObject.selectedDeliveryMethod === 'delivery' && this.appService.appObject.settings.is_postalcode === 'true' && (!this.appService.appObject.postalCodeDetails || !this.appService.appObject.postalCodeDetails.code)) {
        this.navigateTo(this.POPOVER_TYPES.LOCATION_SELECT);
        return;
      }
      if (this.appService?.appObject?.selectedDeliveryMethod === 'delivery' && (!this.appService.appObject.myAddress || !this.appService.appObject.myAddress.name
        || !this.appService.appObject.myAddress.lat || !this.appService.appObject.myAddress.lng)) {
        // this.justSelectDeliveryAddress = true;
        if (!this.isPostalModeEnable) this.navigateTo(this.POPOVER_TYPES.LOCATION_SELECT);
        // if postal code mode is on, then redirect strait into checkout page
        else this.navigateTo(this.POPOVER_TYPES.CHECKOUT);
      } else {
        // if user came to login page from checkout ui, then redirect into checkout page
        this.navigateTo(this.POPOVER_TYPES.CHECKOUT);
      }
      // make it false once user redirected to the checkout page
      this.appService.appObject.isOrderProcess = false;
    } else {
      this.navigateTo(this.POPOVER_TYPES.USER_PROFILE);
    }
    // console.log('loginResponse : ', loginResponse);
  }

  /**
   * Wll call back when user select create an account option from login page
   */
  onRegisterVia() {
    this.navigateTo(this.POPOVER_TYPES.REGISTER_VIA);
  }

  /**
   * This will call back when user select register with email option in register page
   */
  onRegisterViaEmail() {
    this.navigateTo(this.POPOVER_TYPES.REGISTER);
  }

  /**
   * This will call back when user choose forgot password option in login page
   */
  onForgotPassword() {
    this.navigateTo(this.POPOVER_TYPES.FORGOT_PASSWORD);
  }

  /**
   * This will call back when user reset password successfuly
   */
  onPasswordResetSuccess() {
    this.navigateTo(this.POPOVER_TYPES.LOGIN);
  }

  /**
   * This will navigate into dynamic pages slug is required
   * used for pnp and tnc
   * @param slug page slug
   */
  onNavigatePages(slug) {
    let pages = this.appService && this.appService.appObject && this.appService.appObject.settings && this.appService.appObject.settings.pages ? this.appService.appObject.settings.pages : [];
    let page = pages.find((page) => page.slug === slug);
    if (page) {
      this.slug = page.slug;
      this.navigateTo({ TYPE: this.POPOVER_TYPES.PAGES.TYPE, TITLE: page.name });
    }
  }

  /**
   * On user change the delivery address from checkout page
   */
  onChangeDeliveryAddress() {
    this.navigateTo(this.POPOVER_TYPES.LOCATION_SELECT);
  }

  /**
   * Will trigger when user checkout as a guest user
   * @param paymentId payment id of the placed order
   */
  onOrderPlaced(paymentId, deliveryType) {
    if (paymentId) {
      this.uniqueId = paymentId;
      this.navigateTo({ TYPE: this.POPOVER_TYPES.ORDER_SUMMARY.TYPE, TITLE: 'key.' + deliveryType })
    }
  }

  /**
   * Will trigger when user select ordering type
   * @param orderType selected ordering type
   */
  async selectOrderType(orderType) {
    let selectedRestaurant = this.appService.appObject.selectedRestaurantDetails;
    if (this.origin === 'web-menu' && orderType === 'dine_in') {
      if (selectedRestaurant.is_bar_mode === 'true') {
        this.appService.appObject.isLoading = true;
        await this.appService.getUserUnpaidActiveOrders();
      }
    } else if (this.appService.appObject.selectedDeliveryMethod === 'dine_in' && selectedRestaurant.is_bar_mode === 'true') {
      this.appService.clearCart();
    }
    this.appService.appObject.selectedDeliveryMethod = orderType;
    this.appService.updateAppObject();
    if (!this.selectedBranch) await this.appService.calculateCartAmounts();
    this.appService.appObject.isLoading = false;
    // this moved from html template page
    if (this.type === this.POPOVER_TYPES.ORDERING_TYPE.TYPE) this.close();
  }

  /**
   * Will trigger when user go to question page opening after adding item into cart
   * @param categoryData selected category data
   */
  openQuestionDishPage(categoryData) {
    if (categoryData) this.selectedQuestionCategory = categoryData;
  }

  /**
   * Will trigger when user click back button from cross selling dishes list page
   */
  navigateBackwardFromQuestionPage() {
    this.selectedQuestionCategory = null;
    this.crossSelling.navigateBackInWebVersion();
  }

  /**
   * Will trigger when user successfully created a reservation
   * @param response response from table reservation form
   */
  onReservationSuccess(response) {
    if (response) {
      this.reservationSuccess = response;
      this.title = 'key.reservation_request_submitted';

      // change the hight of the popup container to get nicer look of the success message
      let element = document.getElementsByClassName('modal-wrapper');
      // console.log('element : ', element);
      if (element && element[0]) {
        element[0].classList.add('height-300-px');
      }
    }
  }

  /**
   * location select page action button
   */
  addressSelectAction() {
    if (this.isFromCheckoutToLocationSelect || this.justSelectDeliveryAddress || this.isFavouriteRestaurantDeliverySearch) {
      this.addressInputChanged = false;
      this.appService.findNearByRestaurants('do-not-redirect', false).then(async (response) => {
        if (this.isFromCheckoutToLocationSelect || this.justSelectDeliveryAddress) {
          let selectedBranch = this.appService.appObject.restaurants.find((restaurant) => restaurant.id === this.appService.appObject.selectedRestaurantDetails.id);
          if (selectedBranch) {

            // get the current delivery cost to compare if the delivery cost is change with new distance 
            let previousDeliveryCost = this.appService.appObject.orderObject.cart_summery.delivery_cost;
            this.appService.appObject.selectedRestaurantDetails.distance_to_location = selectedBranch.distance_to_shop && !isNaN(+selectedBranch.distance_to_shop) ? (+selectedBranch.distance_to_shop) : 0;

            // this.address
            await this.appService.calculateCartAmounts();
            // if there is distance change and the delivery cost has changed,
            // notify the user that the delivery cost has been changed
            if(previousDeliveryCost !== this.appService.appObject.orderObject.cart_summery.delivery_cost){
              this.translate.get('key.delivery_cost_effected').subscribe((translation) =>{
                this.appService.showToast(translation,'tertiary',3000);
              });
            }

            if (!this.justSelectDeliveryAddress && this.isFromCheckoutToLocationSelect) this.navigateBackward();
            else this.close();
          } else {
            this.appService.clearAddress('selected_restaurant_cannot_delivery_to_selected_address');
          }
        } else if (this.isFavouriteRestaurantDeliverySearch) { // when user from selected favourite restaurant delivery type select
          let favouriteBranch = this.appService.appObject.restaurants.find((restaurant) => restaurant.id === this.appService.appObject.favouriteRestaurant.id);
          if (favouriteBranch) {
            this.router.navigate(['menu', this.appService.appObject.favouriteRestaurant.slug]);
            this.close();
          } else {
            this.appService.clearAddress('your_favourite_restaurant_cannot_delivery_to_selected_address');
          }
        }
      }).catch((error) => {
        console.log('error : ', error);
      });
    } else {
      this.navigateTo('restaurant-list');
    }
  }

  /**
   * Will trigger when user select a table from table list
   * @param selectedTable selected table object
   */
  onSelectTable(selectedTable) {
    // console.log('selectedTable : ', selectedTable);
    this.selectedTable = { ...selectedTable };
    // console.log('justToSelectATable : ' , );
    this.appService.appObject.orderObject.selectedTable = this.selectedTable;
    if (this.justToSelectATable) {
      this.close({ status: true });
    }
    if (this.selectedRestaurant && this.selectedRestaurant.slug) {
      this.appService.updateAppObject();
      this.router.navigate(['/menu', this.selectedRestaurant.slug]);
      this.close();
    }
  }

  /**
   * This function will handle when bar mode is enabled and user select dine_in as ordering option
   * @param extraData currently selected restaurant data
   */
  async handleBarModeNavigations(extraData) {
    this.appService.appObject.isLoading = true;
    this.selectedRestaurantTableList = [];
    this.selectedTable = null;
    // get user unpaid orders if availabel to avoid placing orders on table else
    await this.appService.getUserUnpaidActiveOrders();
    // check if user have a pending order in kitchen, otherwise allow user to select a table and procced order
    if (this.appService.appObject.orderObject && this.appService.appObject.orderObject.selectedTable) {
      // get unpaid order restaurant details
      let selectedRestaurant = this.appService.appObject.restaurants.find((rest) => rest.id === this.appService.appObject.orderObject.resturent_id);
      // if the current selected restaurant and the previously ordered restaurnt is the same, Then proceed
      // otherwise show a message to the user that he has an pending payment order in another restaurant and redirect into that restaurant
      if (this.appService.appObject.orderObject.resturent_id === extraData.id) {
        this.appService.appObject.selectedRestaurantId = extraData.id;
        await this.appService.updateAppObject();
        this.router.navigate(['/menu', selectedRestaurant.slug]);
        this.close();
      } else if (this.appService.appObject.orderObject.resturent_id === selectedRestaurant.id) {
        this.type = this.POPOVER_TYPES.TABLE_SELECT.TYPE;
        this.title = this.POPOVER_TYPES.TABLE_SELECT.TITLE;

        this.showPreviousPendingPaymentOrder = true;
        this.previousRestaurant = {
          name: selectedRestaurant.name,
          number: this.appService.appObject.orderObject.selectedTable.number,
          countDown: 5,
          slug: selectedRestaurant.slug
        };
        // clear the history stack, no need to navigate back because user will redirect into the restaurant menu and popup will be closed
        this.historyStack = [];
        this.appService.appObject.isLoading = false;
        let interval = setInterval((int) => {
          this.previousRestaurant.countDown--;
          // console.log('this.previousRestaurant: ' , this.previousRestaurant.count);
          if (this.previousRestaurant.countDown === 0) {
            clearInterval(interval);

            this.router.navigate(['/menu', this.previousRestaurant.slug]);
            this.close();
          }
        }, 1000)
      }
    } else {
      this.historyStack.push(this.POPOVER_TYPES.RESTAURANT_SELECT);
      this.type = this.POPOVER_TYPES.TABLE_SELECT.TYPE;
      this.title = this.POPOVER_TYPES.TABLE_SELECT.TITLE;
      // keep restaurant data to navigate after selecting table
      this.selectedRestaurant = extraData;

      this.appService.fetchAllTablesOfRestaurant(extraData.id).then((response: any) => {
        // console.log('table data : ' , response);
        if (response.status) this.selectedRestaurantTableList = response.data;

        this.appService.appObject.isLoading = false;
      }).catch((error) => {
        console.log('error while fetching tables : ', error);
        this.appService.appObject.isLoading = false;
      });
    }
  }

  /**
   * Related to the point system, if user decide to go back and login to account for purchase rewards, this function will call
   */
  onBackLoginFromCheckout() {
    this.historyStack = [];

    this.isOrderProcessing = true;
    this.type = this.POPOVER_TYPES.LOGIN.TYPE;
    this.title = this.POPOVER_TYPES.LOGIN.TITLE;
  }

  /**
   * Mark selected restaurant as favourite 
   * @param restaurant selected restaurant object
   */
  async markRestaurantAsFavourite(restaurant) {
    this.appService.appObject.favouriteRestaurant = restaurant;
    await this.appService.updateAppObject();
    this.modalCtr.dismiss();

    const modal = await this.modalCtr.create({
      component: ModalPopoverPage,
      cssClass: 'custom-popup',
      componentProps: {
        'type': this.POPOVER_TYPES.FAVOURITE_RESTAURANT_SELECTED.TYPE,
        'title': this.POPOVER_TYPES.FAVOURITE_RESTAURANT_SELECTED.TITLE
      },
      keyboardClose: false
    });
    setTimeout(async () => {
      await modal.present();
    }, 1000);
  }

  /**
   * When user select delivery type from favourite restaurant
   * @param option selected option
   */
  onFavoriteModeDeliveryMethodSelect(option) {
    this.appService.appObject.selectedDeliveryMethod = option;
    this.appService.updateAppObject();

    if (option === 'delivery') {
      this.isFavouriteRestaurantDeliverySearch = true;
      this.navigateTo(this.POPOVER_TYPES.LOCATION_SELECT)
    } else {
      this.close();
      this.router.navigate(['menu', this.appService.appObject.favouriteRestaurant.slug]);
    }
    // if (this.appService.appObject.favouriteRestaurant.is_bar_mode === 'true' && this.appService.appObject.selectedDeliveryMethod === 'dine_in') {
    //   this.handleBarModeNavigations(this.appService.appObject.favouriteRestaurant);
    // }else{
    //   this.close();
    //   this.router.navigate(['menu',this.appService.appObject.favouriteRestaurant.slug]);
    // } 
  }

  /**
   * get postal code list
   */
  async getPostalCodeList() {

    this.postalCodeRestaurantList = [];
    this.postalCodeList = [];
    this.selectedArea = null;

    // this.appService.getAllRestaurants().then((response: any) => {
    //   if (response.status) {
    //     this.postalCodeRestaurantList = response.data.filter((restaurant) => restaurant.is_postalcode === 'true');
    //     for (let index = 0; index < this.postalCodeRestaurantList.length; index++) {
    //       const element = this.postalCodeRestaurantList[index];
    //       // convert into json object 
    //       element.postal_code_details = element.postal_code_details ? JSON.parse(element.postal_code_details) : []
    //       this.postalCodeList = this.postalCodeList.concat(element.postal_code_details);
    //     }

    //     // remove duplicated postal codes
    //     this.postalCodeList = [...new Map(this.postalCodeList.map((m) => [m.code, m])).values()];
    //   }
    //   console.log('this.postalCodeList : ', this.postalCodeList);
    // }).catch((error) => {
    //   console.log('error : ', error);
    // });
    this.appService.getAllRestaurants().then((response: any) => {
      if (response.status) {
        this.postalCodeRestaurantList = response.data.filter((restaurant) => restaurant.postal_code_details);
        for (let index = 0; index < this.postalCodeRestaurantList.length; index++) {
          const element = this.postalCodeRestaurantList[index];
          // convert into json object 
          element.postal_code_details = element.postal_code_details ? JSON.parse(element.postal_code_details) : []
          for (let index1 = 0; index1 < element.postal_code_details.length; index1++) {
            // get each postal code data and put into postalCodeList global array
            // if multiple restaurants deliver to the same postal code, all prices will be available in 
            // selected postal code object
            const postalCodeData = element.postal_code_details[index1];
            let found = this.postalCodeList.find((post) => post.code === postalCodeData.code && post.name === postalCodeData.name);
            // to avoid duplicates, check by postal code and prices
            if (found) {
              found.price.push({ restaurantId: element.id, cost: postalCodeData.cost, minOrderAmount: postalCodeData.min_order })
            } else {
              let postalData = {
                code: postalCodeData.code,
                name: postalCodeData.name,
                price: [{ restaurantId: element.id, cost: postalCodeData.cost, minOrderAmount: postalCodeData.min_order }]
              };
              this.postalCodeList.push(postalData);
            }
          }
        }
      }
    }).catch((error) => {
      console.log('error : ', error);
    });
    this.isPostalModeEnable = this.appService.appObject.settings.is_franchise === 'true' ? false : this.appService.appObject.settings.is_postalcode == 'true' ? true : false;
  }

  /**
   * 
   * @param ev event 
   */
  getPostalItems(ev: any) {
    this.appService.appObject.errors = [];
    const val = ev.target.value;
    if (val && val.trim() !== '') {
      this.isPostalItemAvailable = true;
      this.postalFilterItem = this.postalCodeList.filter((item) => item.name.toLowerCase().startsWith(val.toLowerCase()) || item.code.toLowerCase().startsWith(val.toLowerCase()))
      // this.postalFilterItem = this.postalFilterItem.slice(0, 2)
    } else {
      this.isPostalItemAvailable = false;
      this.selectedArea = null
    }
  }

  /**
   * 
   * @param item selected postal item
   */
  selectPostalCode(item) {
    this.selectedArea = item;
    this.selected_postal = item.code + ' - ' + item.name;
  }


  /**
   * postal code select and click ok btn
   */
  async onPostalCodeSelect() {
    if (this.selectedArea == null) { this.appService.appObject.errors['postalCode'] = ['select_postal_code']; return; }

    this.appService.appObject.postalCodeDetails = this.selectedArea;
    await this.appService.updateAppObject();

    // if user came to just select address area or already decide which branch to visit
    if (this.justSelectDeliveryAddress || this.selectedBranch) {
      let postalDetails = [];

      if (this.justSelectDeliveryAddress) {
        postalDetails = this.appService.appObject.selectedRestaurantDetails.postal_code_details ? this.appService.appObject.selectedRestaurantDetails.postal_code_details : [];
      } else {
        postalDetails = this.selectedBranch.postal_code_details ? this.selectedBranch.postal_code_details : [];
      }

      if (!Array.isArray(postalDetails)) postalDetails = JSON.parse(postalDetails);

      // console.log('postalDetails : ' , postalDetails);
      let postalData = postalDetails.find((postal) => postal.code === this.selectedArea.code && postal.name === this.selectedArea.name);
      if (!postalData) this.appService.showToast(this.lang.selected_restaurent_not_delivery_area, 'danger');
      else {
        // if branch already selected then redirect into menu page before dismiss the popup
        if (this.selectedBranch) this.router.navigate(['/menu', this.selectedBranch.slug]);

        this.modalCtr.dismiss();
      }

      return;
    }

    let restaurent = this.postalCodeRestaurantList.filter((obj) => obj.postal_code_details.some((postal) => postal.code == this.selectedArea.code));
    // when user trying to checkout without login and no address area selected 
    // once user logged in if there is no address area selected, user will redirect to address area select ui,
    // then once user select area do all the validations and move to the checkout page
    if (this.isOrderProcessing) {
      let postalDetails = this.appService.appObject.selectedRestaurantDetails.postal_code_details ? this.appService.appObject.selectedRestaurantDetails.postal_code_details : [];
      if (!Array.isArray(postalDetails)) postalDetails = JSON.parse(postalDetails);

      let postalData = postalDetails.find((postal) => postal.code === this.selectedArea.code && postal.name === this.selectedArea.name);
      if (!postalData) this.appService.showToast('selected restaurant won\'t delivery to selected area', 'danger');
      else {
        this.type = this.POPOVER_TYPES.CHECKOUT.TYPE;
        this.title = this.POPOVER_TYPES.CHECKOUT.TITLE;
      }

      return;
    }
    if (restaurent.length == 1) {
      this.appService.appObject.selectedRestaurantDetails = restaurent[0];
      this.appService.appObject.selectedRestaurant = restaurent[0].slug;
      this.router.navigate(['/menu', this.appService.appObject.selectedRestaurant]);
      this.modalCtr.dismiss();
    } else if (restaurent.length > 1) {
      this.appService.appObject.restaurants = restaurent;
      this.type = this.POPOVER_TYPES.RESTAURANT_SELECT.TYPE;
      this.title = this.POPOVER_TYPES.RESTAURANT_SELECT.TITLE;
    } else {
      console.log("Something wrong with modelpopOver 965");
    }
  }

  /**
   * 
   * get languages
   */
  async getLang() {
    await this.translate.get('key').subscribe(
      value => {
        this.lang = value;
      }
    )
  }

  async closeUnavailableNote(){
    (await this.modalCtr.getTop()).dismiss();
  }
}
